var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("group-dropdown", {
    attrs: {
      groups: _vm.groups,
      "selected-group-id": _vm.selectedGroupId,
      "show-teachers": _vm.showTeachers,
      "select-group-title": "Selecteer een groep",
      "data-test": "select-group",
      "show-all-groups": false,
      "settings-title": "Instellingen",
      "show-teachers-title": "Toon docent(en)",
      "manual-groups-title": "Jouw groepen",
      "static-groups-title": "Alle groepen",
    },
    on: {
      "group-selected": _vm.changeGroupAndShowTeachers,
      "show-teachers-changed": _vm.changeGroupAndShowTeachers,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }